<template>
  <div class="page">
    <div class="van-nav-tb">
      <van-nav-bar
        title="学时查询详情"
        left-text=""
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="details">
      <div class="details1">
        <div class="details2">
          <i></i>
          <h1>培训班名称</h1>
        </div>
        <span>{{ detralData.className }}</span>
      </div>
      <div class="details1">
        <div class="details2">
          <i></i>
          <h1>课程名称</h1>
        </div>
        <span>{{ detailData.courseName }}</span>
      </div>
      <div class="details1">
        <div class="details2">
          <i></i>
          <h1>培训地点</h1>
        </div>
        <span>{{ detralData.trainingPlace }}</span>
      </div>
      <div class="details1">
        <div class="details2">
          <i></i>
          <h1>主办单位</h1>
        </div>
        <span>{{ detralData.organizedBy }}</span>
      </div>
      <div class="details3">
        <div class="details3_1">
          <div class="details3_2">
            <i></i>
            <h1>开始时间</h1>
          </div>
          <span>{{ detailData.startTime | date("YYYY-MM-DD hh:mm") }}</span>
        </div>
        <div class="details3_1">
          <div class="details3_2">
            <i></i>
            <h1>结束时间</h1>
          </div>
          <span>{{ detailData.endTime | date("YYYY-MM-DD hh:mm") }}</span>
        </div>
      </div>
    </div>
    <div style="height: 20px"></div>
    <GbFooter></GbFooter>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "XscxxqIndex",
  data() {
    return {
      id: "",
      detailData: "", // 用于存储从详情接口获取的数据
      detralData: "", // 用于存储从详情接口获取的数据
    };
  },
  created() {
    if (this.$route.query) {
      this.id = this.$route.query.id;
      this.fetchDetailData(); // 在组件创建时发送请求获取详情数据
    }
  },
  methods: {
    async fetchDetailData() {
      try {
        // 发送GET请求到详情接口，使用id作为查询参数
        const response = await request.get(
          `/client/user/classhour/my/hourSummaryInfo?id=${this.id}`
        );
        this.detailData = response.data.period; // 将获取的数据存储在detailData中
        this.detralData = response.data.trainingClass; // 将获取的数据存储在detralData中
        // this.className = this.detailData.trainingClass.className
        // this.courseName = this.detailData.period.courseName
        // this.startTime = this.detailData.period.startTime
        // this.endTime = this.detailData.period.endTime
      } catch (error) {
        console.error("Error fetching detail data:", error);
      }
    },
  },
};
</script>

<style>
.page {
  width: 100%;
  height: 100vh;
  background-color: #efefef;
}
.details {
  width: 90%;
  min-height: 380px;
  background: #ffffff;
  box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px auto;
}
.details1 {
  width: 90%;
  min-height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: solid 1px #e5e5e5;
  margin: 0 auto;
}
.details2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
}
.details2 i {
  width: 5px;
  height: 14px;
  background: #f52c2e;
  border-radius: 5px;
}
.details2 h1 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #f52c2e;
  margin-left: 10px;
  margin-bottom: 3px;
  margin-top: 3px;
}
.details1 span {
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-bottom: 10px;
}
.details3 {
  width: 90%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
.details3_1 {
  width: 50%;
  min-height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: solid 1px #e5e5e5;
  margin: 0 auto;
}
.details3_2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
}
.details3_2 i {
  width: 5px;
  height: 14px;
  background: #f52c2e;
  border-radius: 5px;
}
.details3_2 h1 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #f52c2e;
  margin-left: 10px;
  margin-bottom: 3px;
  margin-top: 3px;
}
.details3_1 span {
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
</style>
