import { render, staticRenderFns } from "./xscxxq.vue?vue&type=template&id=5bf58892"
import script from "./xscxxq.vue?vue&type=script&lang=js"
export * from "./xscxxq.vue?vue&type=script&lang=js"
import style0 from "./xscxxq.vue?vue&type=style&index=0&id=5bf58892&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports